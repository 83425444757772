import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from "../pages/home/HeaderMenu";
import Sidebar from '../layout/sidebar/sidebar';
import globalConfig from '../jsonConfig/globalConfig.json';
import { Accordion, Card } from 'react-bootstrap';
import axios from "../../services";
import bitcoinIcon from "../../images/profile/btc.png";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import ButtonsMarkets from '../element/ButtonsMarkets'
import ButtonsMarketsIndices from '../element/ButtonsMarketsIndices'
import { useSelector, useDispatch } from 'react-redux'
import { User } from "../store/User/User.action";
import BottomBar from '../layout/sidebar/bottom-bar';
import { Loader } from "./home/components/loader";
import logoShein from "../../images/logo-shein.png"
import logoSpacex from "../../images/spacex.png"
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { Modal, Button, Form } from 'react-bootstrap';


const COLORS = ['red', 'orange', 'yellow', 'lightgreen', 'green'];

const SpeedometerChart = ({ data }) => {
    const maxValue = Math.max(...data.map(item => item.value));
    const maxItem = data.find(item => item.value === maxValue);

    return (
        <PieChart width={400} height={400}>
            <Pie
                data={data}
                cx={200}
                cy={200}
                innerRadius={10}
                outerRadius={140}
                fill="#8884d8"
                dataKey="value"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Legend
                payload={[
                    { value: `Maior: ${maxItem.name} (${maxItem.value})`, type: 'square', color: 'grey' }
                ]}
            />
        </PieChart>
    );
};



function MercadosIPO() {

    const user = useSelector(state => state.user)
    const { t } = useTranslation()
    const [prices, setPrices] = useState([])
    const [mercados, setmercados] = useState(0)
    const parametros = useParams();
    const [currencyTipo, setCurrencyTipo] = useState("")
    const [balance, setBalance] = useState();
    const [balanceShein, setBalanceShein] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const [repos, setRepos] = useState([])

    const [showModal, setShowModal] = useState(false);
    const [balanceInput, setBalanceInput] = useState('');

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const [hasError, setHasError] = useState(false);

    const handleConfirm = async () => {
        try {
            const data = {
                authKey: user.authKey,
                ValorRequisitado: balanceInput,
                // hash: Robot.hashTransaction,
            };

            const response = await axios.post('/marketinvestment/transferipo', data);

            if (response.data.status === 'success') {
                //     openDialogSaque(true);
                //     handleClose();
                //     setisValorSacado(inputValue);
                await getBalances();
                await getBalanceShein();
            }
        } catch (error) {
            console.error('Erro ao confirmar:', error);
            // Aqui você pode tratar o erro, como exibir uma mensagem para o usuário
        }

        handleCloseModal(); // Certifique-se de que esta função está definida
    };



    const handleInputChange = (e) => {
        const value = e.target.value.replace(/[^0-9.,]/g, '');
        setBalanceInput(value);

        // Converter strings para números para comparação
        const numericValue = parseFloat(value.replace(/,/g, '.'));
        const numericBalance = parseFloat(balance);

        // Verificar se o valor inserido é maior que zero e menor ou igual ao saldo
        const isValid = numericValue > 0 && numericValue <= numericBalance;
        setHasError(!isValid);
    }


    const [chartData, setChartData] = useState([
        { name: 'Extreme Fear', value: 20 },
        { name: 'Fear', value: 165 },
        { name: 'Neutral', value: 225 },
        { name: 'Greed', value: 350 },
        { name: 'Extreme Greed', value: 2500 },
    ]);


    const [chartData2, setChartData2] = useState([
        { name: 'Extreme Fear', value: 60 },
        { name: 'Fear', value: 87 },
        { name: 'Neutral', value: 99 },
        { name: 'Greed', value: 560 },
        { name: 'Extreme Greed', value: 1652 },
    ]);

    const generateRandomData = () => [
        { name: 'Extreme Fear', value: Math.floor(Math.random() * 350) },
        { name: 'Fear', value: Math.floor(Math.random() * 300) },
        { name: 'Neutral', value: Math.floor(Math.random() * 150) },
        { name: 'Greed', value: Math.floor(Math.random() * 1000) },
        { name: 'Extreme Greed', value: Math.floor(Math.random() * 3000) },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setChartData(generateRandomData());
        }, 14400000); // Atualiza os dados a cada 4 horas (14400 segundos * 1000 milissegundos)

        return () => clearInterval(interval);
    }, []);


    const getPrices = async () => {
        try {
            const prices = (await axios.get(`/price`)).data
            //console.log(prices)
            setPrices(prices.reverse())
            return prices
        } catch (err) {
            return err.message
        }
    }


    const validUser = async () => {
        try {
            const valid = await axios.put("user/validUser", {
                authKey: user.authKey,
            });
        } catch (error) {
            if (error.response) {
                if (error.response.status && error.response.status == 406) {
                    console.log("invalid user");
                    dispatch(User(null));
                } else {
                    console.log("Unexpected error");
                }
            } else {
                console.log("Unexpected error 404");
            }
        }
    };

    const getBalances = async (currency) => {
        try {
            const balance = (await axios.put(`/wallet/0`, { authKey: user.authKey }))
                .data;
            setBalance(balance.wallet.balance);
            return balance;
        } catch (err) {
            //console.log("aqui");
            return err.response;
        }
    };

    const getBalanceShein = async (currency) => {
        try {
            const balance = (await axios.put(`/wallet/5000`, { authKey: user.authKey }))
                .data;
            setBalanceShein(balance.wallet.balance);
            return balance;
        } catch (err) {
            //console.log("aqui");
            return err.response;
        }
    };


    useEffect(async () => {
        setIsLoading(true)
        await validUser()
        await getBalances();
        await getBalanceShein();
        setIsLoading(false)
    }, [mercados]) //eslint-disable-line


    // useEffect(async () => {
    //     const interval = setInterval(async () => {
    //         await getPrices()
    //         // setmercados(mercados + 1);
    //         // console.log(mercados)
    //     }, 4000);
    //     return () => {
    //         clearInterval(interval);
    //     };

    // }, [mercados]); //eslint-disable-line

    return (
        <>
            <Header2 title={t('Application_Mercados')} />

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                dialogClassName="dark-modal"
                style={{ marginTop: 50 }}
            >
                <Modal.Header closeButton className="bg-dark">
                    <Modal.Title>Alocar Capital</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark">
                    <Form>
                        <Form.Group>
                            <Form.Label style={{ fontSize: 16 }}>Saldo USD {parseFloat(balance).toFixed(2)}$</Form.Label>
                            <Form.Control
                                type="text"
                                value={balanceInput}
                                onChange={handleInputChange}
                                placeholder="Digite o valor a ser alocado"
                            />
                            {hasError && <div style={{ color: 'red' }}>Erro: O valor inserido é maior que o saldo disponível.</div>}

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="bg-dark">
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleConfirm}
                        disabled={hasError || balanceInput === ''}
                    >
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>


            {isLoading ? (
                <Loader />
            ) : (
                <div class="content-body">
                    <div className="container-fluid">

                        <div class="card">
                            <div class="card-title" style={{ margin: 10 }}>
                                <h2>IPOs na Elite Capital</h2>
                            </div>
                            <div class="card-body">
                                <p><strong>Bem-vindo à Elite Capital</strong>, sua porta de entrada para algumas das mais promissoras Ofertas Públicas Iniciais (IPOs) do mercado. Com um forte compromisso em oferecer acesso exclusivo a oportunidades de investimento de alto potencial, a Infinity Capital se destaca como uma plataforma líder no setor financeiro.</p>

                                <h3>O Que Oferecemos:</h3>
                                <ul>
                                    <li><strong>Acesso Exclusivo:</strong> Nossos investidores têm a oportunidade única de participar de IPOs cuidadosamente selecionadas, oferecendo potencial de crescimento e diversificação de portfólio.</li>
                                    <li><strong>Transparência Total:</strong> Com a Elite Capital, você terá todas as informações necessárias para tomar decisões de investimento informadas. Nossos relatórios detalhados e análises de mercado garantem que você esteja sempre um passo à frente.</li>
                                    <li><strong>Suporte Especializado:</strong> Nossa equipe de especialistas está sempre disponível para orientar você em cada etapa do processo de investimento em IPO.</li>
                                </ul>



                                <p>Na <strong>Elite Capital</strong>, estamos comprometidos em empoderar nossos clientes com as melhores oportunidades de investimento. Junte-se a nós hoje e seja parte de um mundo de possibilidades ilimitadas!</p>
                            </div>
                        </div>


                        <div className="card">
                            <div className="card-title" style={{ margin: 10 }}>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-6 col-sm-12 text-center'>
                                        <img src={logoShein} alt="Logo da SHEIN" style={{ maxWidth: '200px', width: '100%' }} />
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <SpeedometerChart data={chartData} />
                                    </div>
                                    <div className='col-lg-4 col-md-12 col-sm-12'>
                                        <h4>
                                            <span style={{ color: 'green', fontSize: 12 }}>disponível</span>
                                        </h4>
                                        <p style={{fontSize:18}}>Saldo: $ {parseFloat(balanceShein).toFixed(2)}</p>
                                        <button className="btn btn-outline-primary" onClick={handleOpenModal}>
                                            Alocar Capital
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>VALOR PREVISTO PARA PRECIFICAÇÃO INICIAL DAS AÇÕES SÃO DE: US$ 49.00 a US$ 51.00 (ATM.)</div>
                                <div>LOTE: 1.000 AÇÕES</div>
                                <div>TOTAL: US$ 51,000.00</div>
                                <div>PRÓX. ATUALIZAÇÃO DE PREÇO: 15-01-2024</div>
                                <div>PREÇO PREVISTO APÓS ATUALIZAÇÃO: US$ 62.50 a US$ 64.70</div>
                                <div>PRECIFICAÇÃO ESTIMADA DA COMPANHIA É DE US$ 66 BILHÕES.</div>
                            </div>
                        </div>





                        <div className="card">
                        <div className="card-title" style={{ margin: 10 }}>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-6 col-sm-12 text-center'>
                                        <img src={logoSpacex} alt="Logo da SpaceX" style={{ maxWidth: '200px', width: '100%' }} />
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <SpeedometerChart data={chartData2} />
                                    </div>
                                    <div className='col-lg-4 col-md-12 col-sm-12'>
                                        <h4>
                                            <span style={{ color: 'green', fontSize: 12 }}>disponível</span>
                                        </h4>
                                        <p>Saldo: $ 0</p>
                                        <button className="btn btn-outline-primary disabled">
                                            Alocar Capital
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>VALOR PREVISTO PARA PRECIFICAÇÃO INICIAL DAS AÇÕES SÃO DE: US$ 92.00 a US$ 97.00 (ATM.)</div>
                                {/* <div>LOTE: 1.000 AÇÕES</div> */}
                                <div>LOTES: 100, 200, 400, 600, 1000 AÇÕES</div>
                                <div>TOTAL: US$ 97,000.00</div>
                                <div>PRÓX. ATUALIZAÇÃO DE PREÇO: 22-11-2024 - 29-11-2024</div>
                                <div>PREÇO PREVISTO APÓS ATUALIZAÇÃO: US$ 200.00 a US$ 250.00</div>
                                <div>PRECIFICAÇÃO ESTIMADA DA COMPANHIA É DE US$ 180 BILHÕES.</div>
                            </div>
                        </div>

                        <div class="row">

                        </div>
                    </div>
                </div>
            )}
            <BottomBar selectedIcon="markets" />
        </>
    )
}

export default MercadosIPO;